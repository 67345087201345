<template>
  <div id="app">
    <navBar></navBar>
    <router-view/>
    <foot></foot>
  </div>
</template>
<script>
import navBar from '../src/components/navBar.vue'
import foot from '../src/components/foot.vue'

export default {
  name: 'Home',
  components: {
     navBar, foot
  },
  data() {
    return {
      broadside_navigation: ['2'],
      foot: ['2'],
      show: false,
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },

}
</script>
<style lang="scss">

</style>
