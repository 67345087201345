import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home')
  },
  {
    path: '/dgh4G-38b',
    name: 'About',
    component: () => import( '../views/低功耗枪机球机/低功耗4G-38版')
  },
  {
    path: '/dgh4G-BMG2',
    name: '低功耗4G-BMG2',
    component: () => import( '../views/Mini电池摄像机/低功耗4G-BMG2')
  },
  {
    path: '/dghwifi-BMG2',
    name: '低功耗WiFi-BMW2',
    component: () => import( '../views/Mini电池摄像机/低功耗WiFi-BMW2')
  },
  {
    path: '/cd4G-MF1',
    name: '长电4G-MF1',
    component: () => import( '../views/Mini电池摄像机/长电4G-MF1')
  },
  {
    path: '/cdxyWiFi-VO15T',
    name: '长电小圆WiFi-VO15T',
    component: () => import( '../views/Mini电池摄像机/长电小圆WiFi-VO15T')
  },
  {
    path: '/cdxfWiFi-VO14T',
    name: '长电小方WiFi-VO14T',
    component: () => import( '../views/Mini电池摄像机/长电小方WiFi-VO14T')
  },
  {
    path: '/hbmld1',
    name: '横版门铃-D1',
    component: () => import( '../views/低功耗门铃/横版门铃-D1')
  },
  {
    path: '/sbmldw4',
    name: '竖版门铃-DW4',
    component: () => import( '../views/低功耗门铃/竖版门铃-DW4')
  },
  {
    path: '/sfmldw5',
    name: '防水门铃-DW5',
    component: () => import( '../views/低功耗门铃/防水门铃-DW5')
  },
  {
    path: '/dghytjwifi',
    name: '低功耗摇头机 WiFi',
    component: () => import( '../views/低功耗云台机/低功耗摇头机 WiFi')
  },
  {
    path: '/dghytj4g',
    name: '低功耗摇头机 4G',
    component: () => import( '../views/低功耗云台机/低功耗摇头机 4G')
  },
  {
    path: '/dgh4gbg2b1',
    name: '低功耗4G-BG2 B1',
    component: () => import( '../views/低功耗电池摄像机/低功耗4G-BG2 B1')
  },
  {
    path: '/dgh4gbg2b2',
    name: '低功耗4G-BG2 B2',
    component: () => import( '../views/低功耗电池摄像机/低功耗4G-BG2 B2')
  },
  {
    path: '/dghwifibw2b1',
    name: '低功耗WiFi-BW2B1',
    component: () => import( '../views/低功耗电池摄像机/低功耗WiFi-BW2B1')
  },
  {
    path: '/dghwifibw2b2',
    name: '低功耗WiFi-BW2B2',
    component: () => import( '../views/低功耗电池摄像机/低功耗WiFi-BW2B2')
  },
  {
    path: '/dgh4gxhr',
    name: '低功耗4G-小黄人版',
    component: () => import( '../views/低功耗枪机球机/低功耗4G-小黄人版')
  },
  {
    path: '/dghwif38',
    name: '低功耗WiFi-38版',
    component: () => import( '../views/低功耗枪机球机/低功耗WiFi-38版')
  },
  {
    path: '/dghwifixhr',
    name: '低功耗WiFi-小黄人版',
    component: () => import( '../views/低功耗枪机球机/低功耗WiFi-小黄人版')
  },
  {
    path: '/cd4g38',
    name: '长电4G-38',
    component: () => import( '../views/低功耗枪机球机/长电4G-38')
  },
  {
    path: '/cd4gxhr',
    name: '长电4G-小黄人版',
    component: () => import( '../views/低功耗枪机球机/长电4G-小黄人版')
  },
  {
    path: '/cdwifi38',
    name: '长电WiFi-38',
    component: () => import( '../views/低功耗枪机球机/长电WiFi-38')
  },
  {
    path: '/cdwifixhr',
    name: '长电WiFi-小黄人版',
    component: () => import( '../views/低功耗枪机球机/长电WiFi-小黄人版')
  },
  {
    path: '/gsjj',
    name: '公司简介',
    component: () => import( '../views/关于我们/公司简介')
  },
  {
    path: '/jrwm',
    name: '加入我们',
    component: () => import( '../views/关于我们/加入我们')
  },
  {
    path: '/yszc',
    name: '隐私政策',
    component: () => import( '../views/关于我们/隐私政策')
  },
  {
    path: '/sdk',
    name: 'SDK开发包',
    component: () => import( '../views/软件下载/SDK开发包')
  },
  {
    path: '/app',
    name: 'APP下载',
    component: () => import( '../views/软件下载/APP下载')
  },
  {
    path: '/qtrjxz',
    name: '其他软件下载',
    component: () => import( '../views/软件下载/其他软件下载')
  },
  {
    path: '/4gfc',
    name: '4G流量分成',
    component: () => import( '../views/平台方案/4G流量分成')
  },
  {
    path: '/yccfc',
    name: '云存储分成',
    component: () => import( '../views/平台方案/云存储分成')
  },
  {
    path: '/fwqcf',
    name: '服务器分布',
    component: () => import( '../views/平台方案/服务器分布')
  },
  {
    path: '/t31zl',
    name: '低功耗T31ZL+Hi3861',
    component: () => import( '../views/核心板/低功耗T31ZL+Hi3861')
  },
  {
    path: '/t31zx',
    name: '低功耗T31ZX+Hi3861',
    component: () => import( '../views/核心板/低功耗T31ZX+Hi3861')
  },
  {
    path: '/t31n',
    name: '长电T31N+Hi3881',
    component: () => import( '../views/核心板/长电T31N+Hi3881')
  },
  {
    path: '/t31x',
    name: '长电T31N+Hi3881',
    component: () => import( '../views/核心板/长电T31X+Hi3881')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
