<template>
  <div class="home">
    <!--    <navBar></navBar>-->
    <!--    轮播-->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <router-link to="/dgh4gbg2b2">
<!--          <img src="../../public/images/home/轮播/低功耗4G-BG2-B2@3x_01.jpg" width="100%" height="100%">-->
          <img src="../../public/images/home/轮播/1.jpg" width="100%" height="100%">
        </router-link>
      </van-swipe-item>
      <van-swipe-item>
        <router-link to="/dghwifi-BMG2">
<!--          <img src="../../public/images/home/轮播/低功耗wifi-BMW2@3x_01.jpg" width="100%" height="100%">-->
          <img src="../../public/images/home/轮播/3.jpg" width="100%" height="100%">
        </router-link>
      </van-swipe-item>
      <van-swipe-item>
        <router-link to="/sbmldw4">
<!--          <img src="../../public/images/home/轮播/横版门铃DW4@3x_01.jpg" width="100%" height="100%">-->
          <img src="../../public/images/home/轮播/2.jpg" width="100%" height="100%">
        </router-link>

      </van-swipe-item>
      <van-swipe-item>
        <router-link to="/dghytjwifi">
<!--          <img src="../../public/images/home/轮播/摇头机-wifi@3x_01.jpg" width="100%" height="100%">-->
          <img src="../../public/images/home/轮播/5.jpg" width="100%" height="100%">

        </router-link>
      </van-swipe-item>
      <van-swipe-item>
        <router-link to="/cdxfWiFi-VO14T">
<!--          <img src="../../public/images/home/轮播/常电小方wifi@3x_01.jpg" width="100%" height="100%">-->
          <img src="../../public/images/home/轮播/4.jpg" width="100%" height="100%">

        </router-link>
      </van-swipe-item>
    </van-swipe>
    <!--    <foot></foot>-->
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import navBar from '../components/navBar.vue'
import foot from '../components/foot.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld, navBar, foot
  },
  data() {
    return {
      broadside_navigation: ['2'],
      foot: ['2'],
      show: false,
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },

}
</script>
<style scoped>
/deep/ .van-cell--clickable {
  font-weight: bold;
}

.router-link-active {
  color: red;
}
</style>
