<template>
  <div>
    <!--    foot-->
    <div class="foot_padding">
      <div>
        <van-collapse  class="bjs" v-model="foot" :border="false">
          <van-collapse-item title="关于我们" name="1">
            <router-link to="/gsjj">
              <div class="location">公司简介</div>
            </router-link>
            <router-link to="/jrwm">
              <div class="location">加入我们</div>
            </router-link>
            <router-link to="/yszc">
              <div class="location">隐私政策</div>
            </router-link>
          </van-collapse-item>
          <van-collapse-item title="联系我们" name="2">
            <van-cell value="邮箱:sales@veepai.com"/>
            <van-cell value="手机:15817227053"/>
            <van-cell value="座机:0755-86641624"/>
            <van-cell value="QQ: 2850579859"/>
          </van-collapse-item>
          <van-collapse-item title="关注我们" name="3">
            <img src="../../public/images/home/w.jpg" width="130px" height="130px">
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="foot_div">
        <div class="foot_size">粤ICP备19104755号</div>
        <div class="foot_size"> Copyright @ 2018-2022深圳市维拍物联智能技术有限公司</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot",
  data() {
    return {
      broadside_navigation: ['2'],
      foot: ['0'],
      show: false,
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },
}
</script>

<style scoped>

.foot_size {
  font-size: 13px;
  width: 99%;
}

.foot_div {
  padding: 16px;
}
.location{
  padding:4%;
}
a{
  color: black;
}
.router-link-active {
  color: red;
}
.bjs{
  width: 100%;
  /*height: 200px;*/
  background-color:#F1F2F4;

}
/deep/.van-cell--clickable{
  background-color:#F1F2F4;

}
/deep/.van-collapse-item__content{
  background-color:#F1F2F4;
}
/deep/.van-cell{
  background-color:#F1F2F4;
}
.foot_padding{
  /*padding-top: 30px;*/
}
</style>