<template>
  <div>
    <van-nav-bar :fixed="true" :placeholder="true">
      <template #left>
        <router-link to="/"exact>
          <div @click="close" class="Home"> <img src="../../public/images/home/weipailogo@3x.png" width="110px" height="32px"></div>
        </router-link>
      </template>
      <template #right>
        <img src="../../public/images/home/更多-更多条件.png" width="18px" height="18px" @click="showPopup">
        <van-popup round get-container="body" closeable v-model="show" position="right"
                   :style="{ height: '100%', width: '70%'}">
          <div style="margin-top:80px">
            <van-collapse accordion v-model="broadside_navigation">
<!--              <van-cell url="/" value="首页" style="font-weight: bold;"/>-->
              <router-link to="/"exact>
                <div @click="close" class="Home">首页</div>
              </router-link>
              <van-collapse-item title="核心板" name="2">
                <router-link to="/t31zl">
                  <div @click="close" class="location">低功耗T31ZL+Hi3861</div>
                </router-link>
                <router-link to="/t31zx">
                  <div @click="close" class="location">低功耗T31ZX+Hi3861</div>
                </router-link>
                <router-link to="/t31n">
                  <div @click="close" class="location">常电T31N+Hi3881</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="Mini电池摄像机" name="3">
                <router-link to="/dgh4G-BMG2">
                  <div @click="close" class="location">低功耗4G-BMG2</div>
                </router-link>
                <router-link to="/dghwifi-BMG2">
                  <div @click="close" class="location">低功耗WiFi-BMW2</div>
                </router-link>
                <router-link  to="/cd4G-MF1">
                  <div  @click="close"  class="location">常电4G-MF1</div>
                </router-link>
                <router-link to="/cdxfWiFi-VO14T">
                  <div @click="close" class="location">常电小方WiFi-VO14T</div>
                </router-link>
                <router-link to="/cdxyWiFi-VO15T">
                  <div @click="close" class="location">常电小圆WiFi-VO15T/A9T</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="低功耗门铃" name="4">
                <router-link to="/hbmld1">
                  <div @click="close" class="location">横版门铃-D1</div>
                </router-link>
                <router-link to="/sbmldw4">
                  <div @click="close" class="location">竖版门铃-DW4</div>
                </router-link>
                <router-link to="/sfmldw5">
                  <div @click="close" class="location">防水门铃-DW5</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="低功耗电池摄像机" name="5">
                <router-link to="/dgh4gbg2b1">
                  <div @click="close" class="location">低功耗4G-BG2 B1</div>
                </router-link>
                <router-link to="/dgh4gbg2b2">
                  <div @click="close" class="location">低功耗4G-BG2 B2</div>
                </router-link>
                <router-link to="/dghwifibw2b1">
                  <div @click="close" class="location">低功耗WiFi-BW2B1</div>
                </router-link>
                <router-link to="/dghwifibw2b2">
                  <div @click="close" class="location">低功耗WiFi-BW2B2</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="低功耗枪机/球机" name="6">
                <router-link to="/dgh4G-38b">
                  <div @click="close" class="location">低功耗4G-38*38</div>
                </router-link>
                <router-link to="/dgh4gxhr">
                  <div @click="close" class="location">低功耗4G-小黄人版</div>
                </router-link>
                <router-link to="/dghwif38">
                  <div @click="close" class="location">低功耗WiFi-38*38</div>
                </router-link>
                <router-link to="/dghwifixhr">
                  <div @click="close" class="location">低功耗WiFi-小黄人版</div>
                </router-link>
                <router-link to="/cd4g38">
                  <div @click="close" class="location">常电4G-38*38</div>
                </router-link>
                <router-link to="/cd4gxhr">
                  <div @click="close" class="location">常电4G-小黄人版</div>
                </router-link>
                <router-link to="/cdwifi38">
                  <div @click="close" class="location">常电WiFi-38*38</div>
                </router-link>
                <router-link to="/cdwifixhr">
                  <div @click="close" class="location">常电WiFi-小黄人版</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="低功耗云台机" name="7">
                <router-link to="/dghytj4g">
                  <div @click="close" class="location">低功耗摇头机 4G</div>
                </router-link>
                <router-link to="/dghytjwifi">
                  <div @click="close" class="location">低功耗摇头机 WiFi</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="软件下载" name="8">
                <router-link to="/sdk">
                  <div @click="close" class="location">SDK开发包</div>
                </router-link>
                <router-link to="/app">
                  <div @click="close" class="location">小鹰看看</div>
                </router-link>
              </van-collapse-item>
              <van-collapse-item title="平台方案" name="9">
                <router-link to="/4gfc">
                  <div @click="close" class="location">4G流量分成</div>
                </router-link>
                <router-link to="/yccfc">
                  <div @click="close" class="location">云存储分成</div>
                </router-link>
                <router-link to="/fwqcf">
                  <div @click="close" class="location">服务器分布</div>
                </router-link>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-popup>
      </template>
    </van-nav-bar>
  </div>
</template>
<!--<router-link to="/qtrjxz">-->
<!--<div @click="close" class="location">其他软件下载</div>-->
<!--</router-link>-->

<script>
export default {
  name: "navBar",
  data() {
    return {

      broadside_navigation: ['2'],
      show: false,
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    close() {

      this.show = false;
    }
  },
}
</script>

<style scoped>
/deep/ .van-cell--clickable {
  font-weight: bold;
}
a{
  color: black;
}
.router-link-active {
  color: red;
}
.location{
  padding:4%;
}
.Home{
  padding:6%;
}
</style>